import React from 'react';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import Navbar from './Header';

export default function Layout({ children }) {
  return (
    <div className="main">
      <GlobalStyles />
      <Typography />
      <Navbar />
      <div className="content">{children}</div>
    </div>
  );
}

import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

  :root {
    --red: #FF4949;
    --black: #111111;
    --yellow: #ffc600;
    --white: #ffffff;
    --grey: #c7c7c4;
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 100%;
    height: 100%;
  }

  body {
    min-height:  100%;
    vertical-align: bottom;
    background-color: var(--black);
  }

  .main {
    display: grid;
    height: 100vh;
  }
  /* .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  } */

  img {
    max-width: 100%;
  }
`;

export default GlobalStyles;

import styled from 'styled-components';

const NavbarStyles = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-self: flex-start;
  z-index: 1;
  justify-content: left;
  text-align: left;
  padding: 2em;
  ul {
    list-style: none;
    padding: 0;
    color: var(--grey);

    a {
      color: var(--white);

      &:hover {
        color: var(--black);
        text-decoration: underline;
      }

      &[aria-current='page'] {
        text-decoration: underline;
        color: var(--grey);
      }
    }
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr 1fr;
    justify-content: left;
    text-align: left;
  }
`;

export default NavbarStyles;

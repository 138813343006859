import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import NavbarStyles from '../styles/NavbarStyles';

export default function Navbar() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          instagram
        }
      }
    }
  `);
  const { title, description, instagram } = data.site.siteMetadata;

  return (
    <NavbarStyles>
      <Link to="/">{title}</Link>
      <p>{description}</p>
      <Link to="/info">INFO</Link>
      <a
        href="https://www.instagram.com/gilfelixperez"
        target="_blank"
        rel="noreferrer"
      >
        {instagram}
      </a>
    </NavbarStyles>
  );
}

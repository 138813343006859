import { createGlobalStyle } from 'styled-components';
import ThunderExtraBoldLCEot from '../fonts/Thunder-ExtraBoldLC.otf';
import ThunderExtraBoldLCWoff from '../fonts/Thunder-ExtraBoldLC.woff';
import ThunderExtraBoldLCWoff2 from '../fonts/Thunder-ExtraBoldLC.woff2';
import ThunderExtraBoldLCTtf from '../fonts/Thunder-ExtraBoldLC.ttf';

const Typography = createGlobalStyle`
  html {
    color: var(--grey);
  }
  body {
    font-family: 'neue-haas-grotesk-display', sans-serif;
    letter-spacing: 0.03rem;
    font-weight: 500;
    font-size: 1.1rem;
  }
  p {
    text-transform: uppercase;
    line-height: 1.25em;
    margin-bottom: .9rem;
    color: var(--grey);
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight: normal;
    margin: 0;
  }

  a {
    color: var(--grey);
    line-height: 1.25em;
    text-transform: uppercase;
    text-decoration: none;
    &:hover {
      color: var(--white)
    }
    &[aria-current='page'] {
      color: var(--white);
    } 
  }

  .say-hello {
    cursor: grabbing;
  }
`;

export default Typography;
